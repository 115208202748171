import 'react-data-grid/lib/styles.css';

import React, {  useMemo } from 'react';
import Datagrid from 'react-data-grid';
import _ from "lodash";


const CamposGrid = ({jogos}) => {

    const columns = [
        
        
        { key: 'dia', name: 'Dia',width: 'max-content' },
        { key: 'hora', name: 'Hora',width: 'max-content' },
        { key: 'JOGO', name: 'Jogo' ,width: 'max-content',
            renderSummaryCell({ row }) {
                return <strong>Casa: {row.n_pessoascasa} Fora: {row.n_pessoasfora} Total: {row.n_total}</strong>;
              }},
        { key: 'campo', name: 'Campo', width: 'max-content'},
        { key: 'referencia', name: 'Referência',width: 'max-content', },
        { key: 'escalao', name: 'Escalão' ,width: 'max-content',},
        { key: 'grupo', name: 'Grupo' ,width: 'max-content',},
        { key: 'nomecasa', name: 'Equipa Casa' ,width: 'max-content',},
        { key: 'balneariocasa', name: 'Balneário Casa' ,width: 'max-content'},
        { key: 'njogadorescasa', name: 'N_Casa' ,width: 'max-content'},
        { key: 'nomefora', name: 'Equipa Fora' ,width: 'max-content'},
        { key: 'balneariofora', name: 'Balneário Fora' ,width: 'max-content'},
        { key: 'njogadoresfora', name: 'N_Fora',width: 'max-content' },
        { key: 'numcampo', name: 'Num Campo' ,width: 'max-content'},
        { key: 'totaljogadores', name: 'Total',width: 'max-content' },
        { key: 'id', name: 'Id Jogo',width: 'max-content' }
    ];


    const summaryRows = useMemo(() => {
        return [
          {
            id: 'total_0',
            n_pessoascasa: _.sumBy(jogos, 'njogadorescasa'),
            n_pessoasfora: _.sumBy(jogos, 'njogadoresfora'),
            n_total: _.sumBy(jogos, 'njogadorescasa') + _.sumBy(jogos, 'njogadoresfora')
          }
        ];
      }, [jogos]);

    return (
        <Datagrid
            columns={columns}
            rows={jogos}
            topSummaryRows={summaryRows}
            bottomSummaryRows={summaryRows}
            className="fill-grid"
            rowClass={(row, index) =>
                index > 2 && index % 2 == 0 ? "classeCenas" : undefined
              }
        />
    );

}

export default CamposGrid;
    