import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import MainLayout from './hoc/mainLayout';
import Jogos from './components/jogos';
import DetalheJogo from './components/jogos/detalheJogo';
import FormPrincipal from './components/inscricoes/formPrincipal';
import Success from './components/inscricoes/success';
import FormPassword from './components/inscricoes/formPassword';
import InicioFisio from './components/fisio/inicio';
import FormFisio from './components/fisio/formfisio';
import ListaFisio from './components/fisio/listaFisio';
import Agenda from './components/agenda/agenda';
import Campos from './components/campos/campos';

const Routecomp = () => {
    const sub = window.location.host.split(".")[0];
    let applyMain = true;
    let routes = (<></>);

    switch(sub){
        case "fisioterapia":
            routes = ( <><Route path="/admin/adicionar" element={<FormFisio admin={true}/>}/>
                <Route path="/admin/listar" element={<ListaFisio admin={true}/>}/>
                <Route path="/admin" element={<InicioFisio admin={true}/>}/>
                <Route path="/adicionar" element={<FormFisio/>}/>
                <Route path="/" element={<InicioFisio/>}/></>);
            break;
        case "resultados":
            routes = (<><Route path="/jogos/admin/:id" element={<DetalheJogo admin={true}/>}/>
                <Route path="/admin/jogos/" element={<Jogos admin={true}/>}/>                
                <Route path="/jogos/:id" element={<DetalheJogo />}/>
                <Route path="/resultados" element={<Jogos />}/>
                <Route path="/" element={<Jogos />}/></>);
            break;
        case "inscricoes":
            routes = (<><Route path="/inscricoes" element={<FormPrincipal/>}/>
                <Route path="/success" element={<Success />}/>
                <Route path="/" element={<FormPassword />}/></>);
            break;
        case "agenda":
            routes = (<><Route path="/" element={<Agenda />}/></>);
            break;
        case "campos":
            routes = (<><Route path="/" element={<Campos />}/></>);
            applyMain = false;
            break;
        case "localhost:3000": //Testar o que quiser
        routes = (<><Route path="/" element={<Campos />}/></>);
        applyMain = false;
            break;
            }



    return (
    <BrowserRouter>
        { applyMain ? (<MainLayout>
            <Routes>
                {routes}
            </Routes>
        </MainLayout>) : 
        (<Routes>
            {routes}
        </Routes>)
        }
    </BrowserRouter>
);}
    

export default Routecomp