export const GET_ESCALOES = 'GET_ESCALOES';
export const GET_JOGOS = 'GET_JOGOS';
export const GET_CLASSIFICACAO = 'GET_CLASSIFICACAO';
export const GET_JOGO_BY_ID = 'GET_JOGO_BY_ID';
export const CLEAR_JOGO_BY_ID = 'CLEAR_JOGO_BY_ID';
export const ADICIONAR_GOLO = 'ADICIONAR_GOLO';
export const FINALIZAR_JOGO = 'FINALIZAR_JOGO';
export const INICIA_JOGO = 'INICIA_JOGO';
export const GET_EQUIPA = 'GET_EQUIPA';
export const GRAVAR_EQUIPA = 'GRAVAR_EQUIPA';
export const RESET_INSCRICOES = 'RESET_INSCRICOES';
export const APAGAR_MARCADOR = 'APAGAR_MARCADOR';
export const GET_FISIO_ALL = 'GET_FISIO_ALL';
export const GET_FISIO_LOV = 'GET_FISIO_LOV';
export const ADD_FISIO = 'ADD_FISIO';
export const CLEAR_NAVIGATE = 'CLEAR_NAVIGATE';
export const GET_EQUIPAS = 'GET_EQUIPAS';
export const GET_AGENDA = 'GET_AGENDA';
export const GET_JOGOS_CAMPOS = 'GET_JOGOS_CAMPOS';
